import React, { useState } from "react";
import { User, Chat, ChatSession } from "../../../type";
import { motion } from "framer-motion";
import useData from "../../../hooks/use";
import SelfChat from "./SelfChat/SelfChat";
import OtherChat from "./OtherChat/OtherChat";

type ChatExchangeProps = {
  user: User;
  chats: Chat[];
};

function ChatExchange({ user, chats }: ChatExchangeProps) {
  const { state, actions } = useData();

  return (
    <div>
      <br></br>
      {chats.map((c) => {
        if (c.user_id == state.userId) {
          return (
            <div>
              <SelfChat chat={c} />
            </div>
          );
        } else {
          return (
            <div>
              <OtherChat chat={c} />
            </div>
          );
        }
      })}
    </div>
  );
}

export default ChatExchange;
