import React from "react";
import { Chat } from "../../../../type";
import UserIcon from "../UserIcon/UserIcon";
import "./OtherChat.css";
import { motion } from "framer-motion";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { styled } from "@mui/material/styles";
import { dateRender } from "../../../../globals/helpers";

type OtherChatProps = {
  chat: Chat;
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const StyledRatingBlue = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#000066",
  },
  "& .MuiRating-iconHover": {
    color: "#000066",
  },
});

function OtherChat({ chat }: OtherChatProps) {
  return (
    <motion.div
      initial={{ x: 40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 240,
        damping: 15,
      }}
    >
      <div className="other-chat-frame">
        <UserIcon chat={chat} />
        {/* <p className="other-chat-date">{chat.created}</p> */}
      </div>
      <div className="other-chat-textframe">
        <p className="other-chat-text">{chat.content}</p>
      </div>
      <div className="other-chat-funcs">
        <StyledRatingBlue
          max={1}
          value={0}
          name="customized-color"
          // defaultValue={true}
          // getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
          // precision={0.5}
          icon={<ContentCopyOutlinedIcon fontSize="inherit" />}
          defaultValue={0}
          onClick={() => {
            console.log(1);
          }}
          emptyIcon={<ContentCopyOutlinedIcon fontSize="inherit" />}
        />
        {/* <StyledRating
          name="customized-color"
          defaultValue={0}
          max={1}
          getLabelText={(value: number) =>
            `${value} Heart${value !== 1 ? "s" : ""}`
          }
          precision={1}
          icon={<FavoriteIcon fontSize="inherit" />}
          emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
        /> */}
      </div>
    </motion.div>
  );
}

export default OtherChat;
