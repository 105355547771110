import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { motion } from "framer-motion";
import ChatExchange from "./components/ChatExchange";
import { Chat } from "../../type";
import ChatInput from "./components/ChatInput/ChatInput";
import useData from "../../hooks/use";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Chat.css";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AiModel from "./components/AiModal/AiModel";
import type { DialogProps } from "@mui/material";

type ChatApiResponse = {
  data: Chat[];
};

function ChatPage() {
  const { state, actions } = useData();
  const [user, setUser] = useState({ id: state.userId, name: state.userName });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const chatSessionRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState(``);
  const inputContainer = document.querySelector(".input-container");

  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    setModal(true);
  };

  const summarizeText = {
    English: "Summarize",
    Spanish: "Resumir",
    Italian: "Riassumere",
    Polish: "Streszczać",
  };

  const helpText = {
    English: "Help",
    Spanish: "Ayuda",
    Italian: "Aiuto",
    Polish: "Pomoc",
  };

  const helpBlurb = {
    English: `### Welcome to the Community Support Chatbot! Here's a guide to help you navigate the application:  

#### **Chats**
This is the main screen you're currently on. It serves as a chatroom for all individuals and organizations on the platform. Here, you can discuss upcoming events, share resources, or coordinate efforts for the good of the community.  

- **Text Input**: Use the input field at the bottom of the screen to send messages.  
- **Paperclip Button**: Attach files like flyers, documents, or images by clicking the paperclip icon to the left of the text input.  
- **Send Button**: Click the send button to share your message.  
- **AI Button**: The white button with stars above the chat allows you to:
  1. Get a summary of the current conversation.
  2. Open this Help modal for additional guidance.

#### **Language Dropdown**
In the top-left corner, use the language dropdown to switch between **English**, **Spanish**, **Italian**, and **Polish**. This will update the interface to your preferred language.

#### **Other Pages**  
Use the menu in the top-left to navigate to the following sections:  

1. **Shelter**: Find a list of shelters in Connecticut available to immigrants, including addresses and contact information.  
2. **Food**: Access a list of locations providing food assistance in Connecticut.  
3. **Health**: Discover healthcare resources for immigrants, such as clinics and counseling services.  
4. **Education**: Explore educational programs, classes, and opportunities tailored for immigrants.  
5. **Employment**: Browse job assistance programs and workshops to help immigrants find work in Connecticut.  
6. **Donate**: Find ways to contribute to the community through donations of goods or funds.  
7. **Volunteer**: Learn about volunteer opportunities to support immigrant-focused initiatives.  

If you have any questions or need further assistance, feel free to ask the chatbot. We're here to help build a stronger community together!  `,
    Spanish: `### ¡Bienvenido al Chatbot de Apoyo Comunitario! Aquí tienes una guía para ayudarte a navegar por la aplicación:  

#### **Chats**
Esta es la pantalla principal en la que te encuentras ahora. Sirve como sala de chat para todas las personas y organizaciones en la plataforma. Aquí puedes hablar sobre próximos eventos, compartir recursos o coordinar esfuerzos para el beneficio de la comunidad.  

- **Campo de texto**: Usa el campo de texto en la parte inferior de la pantalla para enviar mensajes.  
- **Botón de clip**: Adjunta archivos como folletos, documentos o imágenes haciendo clic en el ícono del clip a la izquierda del campo de texto.  
- **Botón de enviar**: Haz clic en el botón de enviar para compartir tu mensaje.  
- **Botón de IA**: El botón blanco con estrellas sobre el chat te permite:
  1. Obtener un resumen de la conversación actual.  
  2. Abrir esta ventana de ayuda para obtener más orientación.

#### **Menú de idiomas**
En la esquina superior izquierda, usa el menú desplegable para cambiar entre **inglés**, **español**, **italiano** y **polaco**. Esto actualizará la interfaz a tu idioma preferido.

#### **Otras páginas**  
Usa el menú en la esquina superior izquierda para navegar a las siguientes secciones:  

1. **Refugio**: Encuentra una lista de refugios en Connecticut disponibles para inmigrantes, incluyendo direcciones y contactos.  
2. **Alimentos**: Accede a una lista de lugares que ofrecen asistencia alimentaria en Connecticut.  
3. **Salud**: Descubre recursos de salud para inmigrantes, como clínicas y servicios de consejería.  
4. **Educación**: Explora programas educativos, clases y oportunidades diseñadas para inmigrantes.  
5. **Empleo**: Encuentra programas de asistencia laboral y talleres para ayudar a los inmigrantes a encontrar trabajo en Connecticut.  
6. **Donar**: Descubre formas de contribuir a la comunidad mediante donaciones de bienes o fondos.  
7. **Voluntariado**: Aprende sobre oportunidades de voluntariado para apoyar iniciativas enfocadas en inmigrantes.  

Si tienes preguntas o necesitas más ayuda, no dudes en preguntar al chatbot. ¡Estamos aquí para ayudar a construir una comunidad más fuerte juntos!  `,
    Italian: `### Benvenuto al Chatbot di Supporto Comunitario! Ecco una guida per aiutarti a navigare nell'applicazione:  

#### **Chat**
Questa è la schermata principale su cui ti trovi ora. Funziona come una chatroom per tutte le persone e organizzazioni sulla piattaforma. Qui puoi discutere di eventi futuri, condividere risorse o coordinare sforzi per il bene della comunità.  

- **Campo di testo**: Usa il campo di testo in basso allo schermo per inviare messaggi.  
- **Pulsante graffetta**: Allega file come volantini, documenti o immagini facendo clic sull'icona della graffetta a sinistra del campo di testo.  
- **Pulsante invia**: Clicca sul pulsante invia per condividere il tuo messaggio.  
- **Pulsante IA**: Il pulsante bianco con stelle sopra la chat ti consente di:
  1. Ottenere un riepilogo della conversazione corrente.  
  2. Aprire questa finestra di aiuto per ulteriori indicazioni.

#### **Menu della lingua**
In alto a sinistra, usa il menu a tendina per passare tra **inglese**, **spagnolo**, **italiano** e **polacco**. Questo aggiornerà l'interfaccia nella tua lingua preferita.

#### **Altre Pagine**  
Usa il menu in alto a sinistra per navigare alle seguenti sezioni:  

1. **Rifugi**: Trova un elenco di rifugi in Connecticut disponibili per immigrati, inclusi indirizzi e contatti.  
2. **Cibo**: Accedi a un elenco di luoghi che offrono assistenza alimentare in Connecticut.  
3. **Salute**: Scopri risorse sanitarie per immigrati, come cliniche e servizi di consulenza.  
4. **Istruzione**: Esplora programmi educativi, corsi e opportunità dedicate agli immigrati.  
5. **Lavoro**: Trova programmi di assistenza lavorativa e workshop per aiutare gli immigrati a trovare lavoro in Connecticut.  
6. **Donazioni**: Scopri modi per contribuire alla comunità tramite donazioni di beni o fondi.  
7. **Volontariato**: Scopri opportunità di volontariato per supportare iniziative a favore degli immigrati.  

Se hai domande o hai bisogno di ulteriore assistenza, non esitare a chiedere al chatbot. Siamo qui per aiutarti a costruire una comunità più forte insieme!  
`,
    Polish: `### Witamy w Chatbocie Wsparcia Społecznościowego! Oto przewodnik, który pomoże Ci poruszać się po aplikacji:  

#### **Czaty**
To jest główny ekran, na którym się teraz znajdujesz. Służy jako pokój rozmów dla wszystkich osób i organizacji na platformie. Tutaj możesz omawiać nadchodzące wydarzenia, dzielić się zasobami lub koordynować działania na rzecz społeczności.  

- **Pole tekstowe**: Użyj pola tekstowego na dole ekranu, aby wysyłać wiadomości.  
- **Przycisk spinacza**: Dołączaj pliki, takie jak ulotki, dokumenty lub obrazy, klikając ikonę spinacza po lewej stronie pola tekstowego.  
- **Przycisk wyślij**: Kliknij przycisk wyślij, aby udostępnić swoją wiadomość.  
- **Przycisk AI**: Biały przycisk z gwiazdkami nad czatem pozwala:
  1. Otrzymać podsumowanie aktualnej rozmowy.  
  2. Otworzyć to okno pomocy w celu uzyskania dodatkowych wskazówek.

#### **Menu języka**
W lewym górnym rogu użyj rozwijanego menu, aby przełączyć się między **angielskim**, **hiszpańskim**, **włoskim** i **polskim**. To zaktualizuje interfejs na preferowany język.

#### **Inne strony**  
Użyj menu w lewym górnym rogu, aby przejść do następujących sekcji:  

1. **Schronienie**: Znajdź listę schronisk w Connecticut dostępnych dla imigrantów, w tym adresy i kontakty.  
2. **Żywność**: Uzyskaj dostęp do listy miejsc oferujących pomoc żywnościową w Connecticut.  
3. **Zdrowie**: Odkryj zasoby opieki zdrowotnej dla imigrantów, takie jak kliniki i usługi doradcze.  
4. **Edukacja**: Przeglądaj programy edukacyjne, kursy i możliwości zaprojektowane dla imigrantów.  
5. **Zatrudnienie**: Znajdź programy pomocy w zatrudnieniu i warsztaty, które pomogą imigrantom znaleźć pracę w Connecticut.  
6. **Darowizny**: Znajdź sposoby na wsparcie społeczności poprzez darowizny rzeczowe lub finansowe.  
7. **Wolontariat**: Dowiedz się o możliwościach wolontariatu, aby wspierać inicjatywy skoncentrowane na imigrantach.  

Jeśli masz pytania lub potrzebujesz dodatkowej pomocy, śmiało zapytaj chatbota. Jesteśmy tutaj, aby pomóc budować silniejszą społeczność razem!  `,
  };

  const summaryBlub = {
    English: `### Recent Conversations Summary

Discussions between **Grace Lutheran Church** and local immigration support organizations (e.g., Connecticut Refugee Assistance Program and New Haven Immigration Network) are fostering collaboration and community impact. Here's what's happening:

#### Upcoming Events
- **March 15, 2025**: *Pathways to Citizenship Workshop* at Grace Lutheran Church. Offers free legal advice and immigration resources. 
- **March 20, 2025**: Volunteer Training for interpreters and case managers, hosted by New Haven Immigration Network. Grace Lutheran plans to send 5 volunteers.
- **April 2, 2025**: Pop-up donation distribution in Hartford's South End using items collected during the February 20, 2025, drive.

#### Key Initiatives
- **Grant Application**: Joint effort to secure the *Connecticut Immigrant Support Grant* (due **April 15, 2025**) for emergency housing expansion.
- **Community Collaboration**: Strengthened networks between organizations to improve resource sharing.

#### Action Items
- Confirm workshop logistics by **March 5, 2025**.
- Finalize distribution event plan by **March 10, 2025**.
- Register volunteers for training by **March 18, 2025**.

#### Encouraging a Stronger Community
Your efforts are creating a welcoming environment for immigrants. Let’s keep building connections and fostering hope. Together, we can make an even greater impact.

##### Feedback
How was this summary? Share feedback to help us improve future updates and better track important details.`,
    Spanish: `### Resumen de Conversaciones Recientes

Las discusiones entre **Grace Lutheran Church** y organizaciones locales de apoyo a inmigrantes (p. ej., Connecticut Refugee Assistance Program y New Haven Immigration Network) están fomentando la colaboración y el impacto comunitario. Esto es lo que está sucediendo:

#### Próximos Eventos
- **15 de marzo de 2025**: *Taller de Caminos a la Ciudadanía* en Grace Lutheran Church. Ofrece asesoramiento legal gratuito y recursos de inmigración. 
- **20 de marzo de 2025**: Capacitación para voluntarios en interpretación y gestión de casos, organizada por New Haven Immigration Network. Grace Lutheran planea enviar a 5 voluntarios.
- **2 de abril de 2025**: Distribución de donaciones emergente en el South End de Hartford utilizando artículos recolectados durante la campaña del 20 de febrero de 2025.

#### Iniciativas Clave
- **Solicitud de Subvención**: Esfuerzo conjunto para asegurar la *Subvención de Apoyo a Inmigrantes de Connecticut* (vencimiento el **15 de abril de 2025**) para la expansión de vivienda de emergencia.
- **Colaboración Comunitaria**: Redes fortalecidas entre organizaciones para mejorar el intercambio de recursos.

#### Tareas
- Confirmar los detalles logísticos del taller antes del **5 de marzo de 2025**.
- Finalizar el plan del evento de distribución antes del **10 de marzo de 2025**.
- Registrar voluntarios para la capacitación antes del **18 de marzo de 2025**.

#### Fomentando una Comunidad Más Fuerte
Sus esfuerzos están creando un ambiente acogedor para los inmigrantes. Sigamos construyendo conexiones y fomentando la esperanza. Juntos podemos tener un impacto aún mayor.

##### Comentarios
¿Cómo estuvo este resumen? Comparte tus comentarios para ayudarnos a mejorar futuras actualizaciones y rastrear mejor los detalles importantes.
`,
    Italian: `### Sommario delle Conversazioni Recenti

Le discussioni tra **Grace Lutheran Church** e organizzazioni locali di supporto agli immigrati (ad es., Connecticut Refugee Assistance Program e New Haven Immigration Network) stanno promuovendo la collaborazione e l'impatto comunitario. Ecco cosa sta succedendo:

#### Prossimi Eventi
- **15 marzo 2025**: *Workshop sui Percorsi per la Cittadinanza* presso Grace Lutheran Church. Offre consulenza legale gratuita e risorse sull'immigrazione. 
- **20 marzo 2025**: Formazione per volontari su interpretazione e gestione dei casi, ospitata da New Haven Immigration Network. Grace Lutheran prevede di inviare 5 volontari.
- **2 aprile 2025**: Distribuzione di donazioni a Hartford South End utilizzando gli articoli raccolti durante la campagna del 20 febbraio 2025.

#### Iniziative Chiave
- **Richiesta di Sovvenzione**: Sforzo congiunto per ottenere la *Sovvenzione di Supporto agli Immigrati del Connecticut* (scadenza **15 aprile 2025**) per l'espansione degli alloggi di emergenza.
- **Collaborazione Comunitaria**: Rafforzamento delle reti tra organizzazioni per migliorare la condivisione delle risorse.

#### Azioni Necessarie
- Confermare i dettagli logistici del workshop entro il **5 marzo 2025**.
- Finalizzare il piano per l'evento di distribuzione entro il **10 marzo 2025**.
- Registrare i volontari per la formazione entro il **18 marzo 2025**.

#### Incentivare una Comunità Più Forte
I vostri sforzi stanno creando un ambiente accogliente per gli immigrati. Continuiamo a costruire connessioni e a coltivare la speranza. Insieme possiamo avere un impatto ancora maggiore.

##### Feedback
Com'è stato questo sommario? Condividi i tuoi commenti per aiutarci a migliorare i futuri aggiornamenti e a tracciare meglio i dettagli importanti.
`,
    Polish: `### Podsumowanie Ostatnich Rozmów

Dyskusje między **Grace Lutheran Church** a lokalnymi organizacjami wspierającymi imigrantów (np. Connecticut Refugee Assistance Program i New Haven Immigration Network) wspierają współpracę i wpływ na społeczność. Oto, co się dzieje:

#### Nadchodzące Wydarzenia
- **15 marca 2025**: *Warsztaty Ścieżki do Obywatelstwa* w Grace Lutheran Church. Oferuje bezpłatne porady prawne i zasoby imigracyjne. 
- **20 marca 2025**: Szkolenie wolontariuszy dla tłumaczy i menedżerów spraw, organizowane przez New Haven Immigration Network. Grace Lutheran planuje wysłać 5 wolontariuszy.
- **2 kwietnia 2025**: Dystrybucja darowizn w South End w Hartford z wykorzystaniem przedmiotów zebranych podczas zbiórki 20 lutego 2025.

#### Kluczowe Inicjatywy
- **Wniosek o Dotację**: Wspólny wysiłek w celu uzyskania *Dotacji Wspierającej Imigrantów w Connecticut* (termin **15 kwietnia 2025**) na rozbudowę mieszkań awaryjnych.
- **Współpraca Społecznościowa**: Wzmocnione sieci między organizacjami w celu ulepszenia wymiany zasobów.

#### Zadania
- Potwierdzenie szczegółów logistycznych warsztatów do **5 marca 2025**.
- Finalizacja planu dystrybucji do **10 marca 2025**.
- Rejestracja wolontariuszy na szkolenie do **18 marca 2025**.

#### Wspieranie Silniejszej Społeczności
Twoje wysiłki tworzą przyjazne środowisko dla imigrantów. Budujmy dalej połączenia i wspierajmy nadzieję. Razem możemy mieć jeszcze większy wpływ.

##### Informacja Zwrotna
Jak podobało się to podsumowanie? Podziel się swoją opinią, aby pomóc nam ulepszyć przyszłe aktualizacje i lepiej śledzić ważne szczegóły.`,
  };

  const [chats, setChats] = useState<Chat[]>([]);

  useEffect(() => {
    if (chatSessionRef.current) {
      chatSessionRef.current.scrollTop = chatSessionRef.current.scrollHeight;
    }
    const allElements = document.querySelectorAll("*");
    let tallestElement = null;
    let maxHeight = 0;

    allElements.forEach((element) => {
      const height = element.scrollHeight;
      if (height > maxHeight) {
        maxHeight = height;
        tallestElement = element;
      }
    });

    // Scroll to the bottom of the tallest element
    if (tallestElement) {
      // @ts-expect-error
      tallestElement.scrollTop = tallestElement.scrollHeight;
    }
  }, [chats]);

  useEffect(() => {
    fetch("https://bashux.com/chats", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: state.authToken ? state.authToken : "",
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((d: ChatApiResponse) => {
        setChats(d.data);
      })
      .catch((e) => {
        console.log("Error");
        console.log(e);
      });

    // const allElements = document.querySelectorAll("*");
    // let tallestElement = null;
    // let maxHeight = 0;

    // allElements.forEach((element) => {
    //   const height = element.scrollHeight;
    //   if (height > maxHeight) {
    //     maxHeight = height;
    //     tallestElement = element;
    //   }
    // });

    // // Scroll to the bottom of the tallest element
    // if (tallestElement) {
    //   // @ts-expect-error
    //   tallestElement.scrollTop = tallestElement.scrollHeight;
    // }
  }, []);

  const handleHelpModal = () => {
    setModalText(helpBlurb[state.language]);
  };

  const handleAiSumm = () => {
    setModalText(summaryBlub[state.language]);
  };

  return (
    <div className="chat-frame">
      <div className="chat-nav">
        <Button
          size="small"
          startIcon={<AutoAwesomeIcon />}
          variant="outlined"
          sx={{
            width: "70px",
            // marginRight: "5px",
            // marginLeft: "5px",
            height: "40px",
          }}
          onClick={handleClick}
        >
          AI
        </Button>
        {
          <AiModel
            text={modalText}
            setText={setModalText}
            open={modal}
            setOpen={setModal}
          />
        }
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => {
            setAnchorEl(null);
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={() => {
              handleCloseModal();
              handleAiSumm();
            }}
            sx={{ width: "180px", textAlign: "left", height: "50px" }}
          >
            <SummarizeIcon />
            <p className="chat-ai-option-text">
              {summarizeText[state.language]}
            </p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseModal();
              handleHelpModal();
            }}
            sx={{ width: "180px", textAlign: "left", height: "50px" }}
          >
            <LiveHelpIcon />
            <p className="chat-ai-option-text">{helpText[state.language]}</p>
          </MenuItem>
        </Menu>
      </div>

      <div className="chat-session">
        <ChatExchange user={user} chats={chats} />
        <ChatInput chats={chats} setChats={setChats} />
      </div>
    </div>
  );
}

export default ChatPage;
