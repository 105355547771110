import React from "react";
import { Chat } from "../../../../type";
import UserIcon from "../UserIcon/UserIcon";
import "./SelfChat.css";
import { motion } from "framer-motion";

type SelfChatProps = {
  chat: Chat;
};

function SelfChat({ chat }: SelfChatProps) {
  return (
    <motion.div
      initial={{ x: -40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 240,
        damping: 15,
      }}
    >
      <div className="self-chat-frame">
        <p className="self-chat-text">{chat.content}</p>
        {/* <UserIcon chat={chat} /> */}
        {/* <p className="self-chat-date">{chat.created}</p> */}
      </div>
    </motion.div>
  );
}

export default SelfChat;
