import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import "./ChatInput.css";
import { TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ToggleButton from "@mui/material/ToggleButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Chat } from "../../../../type";
import useData from "../../../../hooks/use";

type ChatInputProps = {
  chats: Chat[];
  setChats: Dispatch<SetStateAction<Chat[]>>;
};

type ChatCreateResponse = {
  id: string;
  user_id: string;
  content: string;
  status_code: number;
};

function ChatInput({ setChats, chats }: ChatInputProps) {
  const [userText, setUserText] = useState("");
  const { state, actions } = useData();
  const handleChange = (e: any) => {
    setUserText(e.target.value);
  };

  const handleClick = () => {
    fetch("https://bashux.com/create_chat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: state.authToken ? state.authToken : "",
      },
      body: JSON.stringify({ user_id: state.userId, content: userText }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((d: ChatCreateResponse) => {
        const additive = {
          id: d.id,
          created: "Right now!",
          user_id: state.userId,
          user_name: state.userName,
          content: userText,
        };
        setChats([...chats, additive]);
        setUserText("");
      })
      .catch((e) => {
        console.log("Error");
        console.log(e);
      });
  };

  return (
    <div className="chat-input-frame">
      {/* <p></p> */}
      <ToggleButton
        sx={{
          backgroundColor: "#F0F0F0",
          borderRadius: "0px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "black",
          borderStyle: "none",
          position: "relative",
          top: "0px",
          left: "1px",
          height: "49px",
        }}
        value="left"
        aria-label="left aligned"
      >
        <AttachFileIcon />
      </ToggleButton>
      <TextField
        size="small"
        value={userText}
        onChange={handleChange}
        multiline
        variant="filled"
        sx={{ width: "60%" }}
        maxRows={20}
      />

      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          height: "47px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
        startIcon={<SendIcon sx={{ position: "relative", left: "8px" }} />}
      ></Button>
    </div>
  );
}

export default ChatInput;
