import React from "react";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Chat } from "../../../../type";
import PersonIcon from "@mui/icons-material/Person";
import "./UserIcon.css";
import { dateRender } from "../../../../globals/helpers";

type UserIconProps = {
  chat: Chat;
};

function UserIcon({ chat }: UserIconProps) {
  return (
    <div className="user-icon-frame">
      <PersonPinIcon
        // fontSize="38" // Optional: Set the size (small, medium, large, inherit, etc.)
        color="primary" // Optional: Set the color (inherit, primary, secondary, etc.)
        style={{ marginRight: "5px", marginLeft: "5px", fontSize: "42px" }} // Optional: Add custom styles
      />
      <div className="user-icon-sectional">
        <p className="user-icon-name">{chat.user_name}</p>
        <p className="user-icon-date">{dateRender(chat.created)}</p>
      </div>
    </div>
  );
}

export default UserIcon;
