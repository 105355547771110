export const dateRender = (dateString: string) => {
  const date = new Date(dateString);

  // Format the Date object to EST using Intl.DateTimeFormat
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/New_York", // EST time zone
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const estTime = new Intl.DateTimeFormat("en-US", options).format(date);
  return estTime;
};
